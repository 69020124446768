import React from "react"
import "../../assets/css/main.scss"
import logo from "../../assets/images/gmb-logo.png"
import { Button, Container, Form, Alert } from 'react-bootstrap'
import { Formik } from 'formik'
import { initFomForgotPassword, valid_formForgotPassword } from './common/const'
import { URL } from "../../constants/Url"
import { serializeForm } from '../../helper'
import { post_checkVerifikasi } from '../../actions/auth'
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { cksClient } from '../../helper'
import { CustomInfoMessage } from "../../components/custom"

class checkVerifikasi extends React.Component {
    constructor(props) {
        super(props)
        this.state = { 
            formLogin: {
                ...initFomForgotPassword,
                url: document.location.origin,
                isLoading: false,
            },
            info: {
                isShow: null,
                infoVariant: null,
                infoBody: null,
            },
            styleIngCheck: {
              'position': 'absolute',
              'top': '0',
              'left': '0',
              'right': '0',
              'bottom': '0',
              'display': 'flex',
              'justify-content': 'center',
              'align-items': 'center',
              'font-size': '53px'       
            }
         }
    }

    componentDidMount () {
      this.checkToken()
    }

    checkToken = (values) => {
      const { props } = this
      const slugs = props.match.params.slug.split('-')
      const uniqId = slugs[0].replace(/[^a-zA-Z0-9]/g, "")
            post_checkVerifikasi({token: uniqId}, 
                resp => {
                    // const { data } = resp
                    props.history.push(URL.LOGIN)
                },
                () => {
                  props.history.push(URL.LOGIN)
                }
            )
    }

    set_toggleInfo = (nextState, infoVariant, infoBody) => {
        this.setState({
            info: {
                isShow: nextState,
                infoVariant: infoVariant,
                infoBody: infoBody
            }
        })
    }

    render() {
        const { state } = this

        return (
          <div style={state.styleIngCheck}>
             <FontAwesomeIcon icon={faSpinner} spin />
          </div>
        )
    }
}

export default checkVerifikasi
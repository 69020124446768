import { apiClient } from '../../helper'
import { API } from '../../constants/Url'

const post_loginUser = (params, callbkSucccess, callbkError) => {
    apiClient(false).post(API.LOGIN, params).then(resp => {
        if (resp.ok && resp.status === 200) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Post login has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const post_registerUser = (params, callbkSucccess, callbkError) => {
    apiClient().post(API.REGISTER, params).then(resp => {
        if (resp.ok && resp.status === 200) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Post register has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const post_changePassword = (params, callbkSucccess, callbkError) => {
    apiClient().post(API.CHANGE_PASSWORD, params).then(resp => {
        if (resp.ok && resp.status === 200) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Post change password has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const post_ForgetPassword = (params, callbkSucccess, callbkError) => {
    apiClient().post(API.FORGET, params).then(resp => {
        if (resp.ok && resp.status === 201) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Post change password has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const post_checkForget = (params, callbkSucccess, callbkError) => {
    apiClient().post(API.CHECK_FORGET, params).then(resp => {
        if (resp.ok && resp.status === 201) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Post change password has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const post_checkVerifikasi = (params, callbkSucccess, callbkError) => {
    apiClient().post(API.CHECK_VERIFIKASI, params).then(resp => {
        if (resp.ok && resp.status === 201) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Post change password has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const post_authChangePassword = (params, callbkSucccess, callbkError) => {
    apiClient().post(API.CHANGE_PASSWORD_PENGGUN, params).then(resp => {
        if (resp.ok && resp.status === 201) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Post change password has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const postRegisterGoogle = (params, callbkSucccess, callbkError) => {
    apiClient().post(API.REGISTER_GOOGLE, params).then(resp => {
        if (resp.ok && resp.status === 200) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Post change password has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const postLoginGoogle = (params, callbkSucccess, callbkError) => {
    apiClient().post(API.LOGIN_GOOGLE, params).then(resp => {
        if (resp.ok && resp.status === 200) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Post change password has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}


export {
    post_loginUser,
    post_registerUser,
    post_changePassword,
    post_ForgetPassword,
    post_checkForget,
    post_checkVerifikasi,
    post_authChangePassword,
    postRegisterGoogle,
    postLoginGoogle
}

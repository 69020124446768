import { apiClient } from '../../helper'
import { API } from '../../constants/Url'

const get_listLocation = (params, callbkSucccess, callbkError) => {
    apiClient().get(API.LOCATION, params).then(resp => {
        if (resp.ok && resp.status === 200) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Get list location has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

const get_pembayaranInfo = (params, callbkSucccess, callbkError) => {
    apiClient().get(API.PEMBAYARAN_INFO, params).then(resp => {
        if (resp.ok && resp.status === 200) {
            callbkSucccess && callbkSucccess(resp)
        } else {
            console.log(`Get info pembayaran has trouble: ${resp.problem}`)
            callbkError && callbkError(resp)
        }
    })
}

export {
    get_listLocation,
    get_pembayaranInfo
}

import React from "react"
import "../../assets/css/main.scss"
import logo from "../../assets/images/gmb-logo.png"
import { Button, Container, Form, Alert } from 'react-bootstrap'
import { Formik } from 'formik'
import { initFomForgotPassword, valid_formForgotPassword } from './common/const'
import { URL } from "../../constants/Url"
import { serializeForm } from '../../helper'
import { post_ForgetPassword } from '../../actions/auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { cksClient } from '../../helper'
import { CustomInfoMessage } from "../../components/custom"

class ForgotPassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = { 
            formLogin: {
                ...initFomForgotPassword,
                url: document.location.origin,
                isLoading: false,
            },
            info: {
                isShow: null,
                infoVariant: null,
                infoBody: null,
            }
         }
    }

    click_buttonSubmit = (values) => {
        const { state } = this
        const formData = serializeForm(initFomForgotPassword, values, 'form-data')
        const formLogin = {...state.formLogin}     
        formLogin.isLoading = true
        this.setState({
            formLogin
        }, () => {
            post_ForgetPassword(values, 
                resp => {
                    const { data } = resp.data

                    formLogin.isLoading = false
                    this.setState({
                        formLogin
                    })
                    this.set_toggleInfo(true, 'success', 'Please check your email for reset password')
                    setTimeout(() => {
                        return this.props.history.push(URL.LOGIN)                        
                    }, 5000);
                },
    
                () => {
                    formLogin.isLoading = false
                    this.set_toggleInfo(true, 'danger', 'Someting wrong, please try again later')
                    this.setState({
                        formLogin
                    })
                }
            ) 
        })
    }

    set_toggleInfo = (nextState, infoVariant, infoBody) => {
        this.setState({
            info: {
                isShow: nextState,
                infoVariant: infoVariant,
                infoBody: infoBody
            }
        })
    }

    render() {
        const { state } = this

        return (
            <div className="welcome-page wrapper">
                <header className="header">
                    <img src={logo} alt="GMB Logo" />
                </header>
                <Container>
                    <div className="welcome-page-body">
                        <p className="welcome-page-title-label">Selamat datang di</p>
                        <h1 className="welcome-page-title">Pusat Pengembangan Literasi Nasional</h1>
                    </div>
                    <div className="welcome-page-title-footer">
                        <CustomInfoMessage 
                            show={state.info.isShow}
                            variant={state.info.infoVariant}
                            header={state.info.infoVariant}
                            body={state.info.infoBody}
                        />
                        <Formik
                            initialValues={state.formLogin}
                            validationSchema={valid_formForgotPassword}
                            onSubmit={this.click_buttonSubmit}
                            validateOnBlur={false}
                            validateOnChange={false}
                            >
                            {formikProps => (
                                <Form>
                                    <Form.Group>
                                        <Form.Label>Email Address</Form.Label>
                                        <Form.Control 
                                            error={formikProps.errors.email}
                                            type="email" 
                                            name="email"
                                            onChange={formikProps.handleChange}
                                            value={formikProps.values.email}
                                            isInvalid={!!formikProps.errors.email}
                                        />

                                        {formikProps.errors.email && (
                                            <Form.Control.Feedback type="invalid">
                                                {formikProps.errors.email}
                                            </Form.Control.Feedback>
                                        )}
                                    </Form.Group>

                                    <Form.Group className="form-footer">
                                        <Button 
                                            type="submit"
                                            onClick={formikProps.handleSubmit}
                                            disabled={state.formLogin.isLoading}
                                        >
                                            {state.formLogin.isLoading ? <FontAwesomeIcon icon={faSpinner} spin />  :'RESET PASSWORD'}
                                        </Button>
                                    </Form.Group>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Container>
            </div>
        )
    }
}

export default ForgotPassword
import React from 'react'
import WelcomePage from "./pages/index"
import Login from "./pages/auth/Login"
import Register from "./pages/auth/Register"
import ForgetPasswordCheck from "./pages/auth/ForgotPasswordCheck"
import checkVerifikasi from "./pages/auth/VerifikasiCheck"
import ForgotPassword from "./pages/auth/ForgotPassword"
import MainContainer from "./pages/main/Container"
import { URL } from './constants/Url'
import PublicRoute from "./components/router/publicRoute"
import PrivateRoute from "./components/router/privateRoute"
import { BrowserRouter as Router, Switch } from 'react-router-dom'
import ChangePassword from './pages/auth/ChangePassword'

const MainRouter = () => (
    <Router>
        <Switch>
            <PublicRoute path="/" component={WelcomePage} exact />
            <PublicRoute path={URL.LOGIN} component={Login} />
            <PublicRoute path={URL.REGISTER} component={Register} />
            <PublicRoute path={URL.FORGOT_PASSWORD} component={ForgotPassword} />
            <PublicRoute path={URL.FORGOT_PASSWORD_CHECK} component={ForgetPasswordCheck} />
            <PublicRoute path={URL.VERIFIKASI_CHECK} component={checkVerifikasi} />
            <PublicRoute path={URL.CHANGE_PASSWORD} component={ChangePassword} />
            <PrivateRoute path="/app/" component={MainContainer} />
        </Switch>
    </Router>
)

export default MainRouter
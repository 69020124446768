import React from "react"
import "../../assets/css/main.scss"
import logo from "../../assets/images/gmb-logo.png"
import { Button, Container, Form, Alert } from 'react-bootstrap'
import { Formik } from 'formik'
import { initFomChangePassword, validFormChangePassword } from './common/const'
import { URL } from "../../constants/Url"
import { serializeForm } from '../../helper'
import { post_authChangePassword } from '../../actions/auth'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import { cksClient } from '../../helper'
import { CustomInfoMessage } from "../../components/custom"

class ChangePassword extends React.Component {
    constructor(props) {
        super(props)
        this.state = { 
            formLogin: {
                ...initFomChangePassword,
                session: '',
                isLoading: false,
            },
            info: {
                isShow: null,
                infoVariant: null,
                infoBody: null,
            },
            showPassword: [
                false,
                false,
                false
            ]
         }
    }

    click_buttonSubmit = (values) => {
        const { state, props } = this
        const slugs = props.match.params.slug.split('-')
        const uniqId = slugs[0].replace(/[^a-zA-Z0-9]/g, "")
        const formData = serializeForm(initFomChangePassword, values, 'form-data')
        const formLogin = {...state.formLogin}     
        formLogin.isLoading = true

        this.setState({
            formLogin
        }, () => {
            post_authChangePassword({ password: values.password, session: uniqId }, 
                resp => {
                    const { data } = resp.data

                    formLogin.isLoading = false
                    this.setState({
                        formLogin
                    })
                    this.set_toggleInfo(true, 'success', 'Your account has been restored')
                    setTimeout(() => {
                        return this.props.history.push(URL.LOGIN)                        
                    }, 5000);
                },
    
                () => {
                    formLogin.isLoading = false
                    this.set_toggleInfo(true, 'danger', 'Someting wrong, please try again later')
                    this.setState({
                        formLogin
                    })
                }
            ) 
        })
    }

    set_toggleInfo = (nextState, infoVariant, infoBody) => {
        this.setState({
            info: {
                isShow: nextState,
                infoVariant: infoVariant,
                infoBody: infoBody
            }
        })
    }

    showingPassword = (key) => {
        const { state } = this
        var showing = state.showPassword
        showing[key] = !showing[key]
        this.setState({
            showPassword: showing
        })
    }

    render() {
        const { state } = this

        return (
            <div className="welcome-page wrapper">
                <header className="header">
                    <img src={logo} alt="GMB Logo" />
                </header>
                <Container>
                    <div className="welcome-page-body pt-5">
                        {/* <p className="welcome-page-title-label">Selamat datang di</p> */}
                        <h1 className="welcome-page-title text-center">Reset Password</h1>
                    </div>
                    <div className="welcome-page-title-footer">
                        <CustomInfoMessage 
                            show={state.info.isShow}
                            variant={state.info.infoVariant}
                            header={state.info.infoVariant}
                            body={state.info.infoBody}
                        />
                        <Formik
                            initialValues={state.formLogin}
                            validationSchema={validFormChangePassword}
                            onSubmit={this.click_buttonSubmit}
                            validateOnBlur={false}
                            validateOnChange={false}
                            >
                            {formikProps => (
                                <Form>
                                    <Form.Group>
                                        <Form.Label>Ketik Kata Sandi Baru Anda</Form.Label>
                                        <div class="position-relative">
                                            <Form.Control 
                                                error={formikProps.errors.password}
                                                type={state.showPassword[0] ? 'text': 'password'}  
                                                name="password"
                                                onChange={formikProps.handleChange}
                                                value={formikProps.values.password}
                                                isInvalid={!!formikProps.errors.password}
                                            />
                                            <span className="form-icon" onClick={(e) => this.showingPassword(0)}><FontAwesomeIcon icon={(state.showPassword[0]) ? faEyeSlash : faEye} /></span>
                                        </div>

                                        {formikProps.errors.password && (
                                            <Form.Control.Feedback type="invalid">
                                                {formikProps.errors.password}
                                            </Form.Control.Feedback>
                                        )}
                                    </Form.Group>
                                    <Form.Group>
                                        <Form.Label>Ketik ulang Kata Sandi Baru Anda</Form.Label>
                                        <div class="position-relative">
                                            <Form.Control 
                                                error={formikProps.errors.password_repeat}
                                                type={state.showPassword[1] ? 'text': 'password'}  
                                                name="password_repeat"
                                                onChange={formikProps.handleChange}
                                                value={formikProps.values.password_repeat}
                                                isInvalid={!!formikProps.errors.password_repeat}
                                            />
                                            <span className="form-icon" onClick={(e) => this.showingPassword(1)}><FontAwesomeIcon icon={(state.showPassword[1]) ? faEyeSlash : faEye} /></span>
                                        </div>

                                        {formikProps.errors.password_repeat && (
                                            <Form.Control.Feedback type="invalid">
                                                {formikProps.errors.password_repeat}
                                            </Form.Control.Feedback>
                                        )}
                                    </Form.Group>

                                    <Form.Group className="form-footer">
                                        <Button 
                                            type="submit"
                                            onClick={formikProps.handleSubmit}
                                            disabled={state.formLogin.isLoading}
                                        >
                                            {state.formLogin.isLoading ? <FontAwesomeIcon icon={faSpinner} spin />  :'RESET PASSWORD'}
                                        </Button>
                                    </Form.Group>
                                </Form>
                            )}
                        </Formik>
                    </div>
                </Container>
            </div>
        )
    }
}

export default ChangePassword
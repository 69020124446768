import React from 'react'
import PropTypes from 'prop-types'
import { Col, Row } from 'react-bootstrap'
import Skeleton from 'react-loading-skeleton'

const CustomCardSkeleton = props => {

    return (
        <>
            <div className="custom-card my-3">
                <Row row="1">
                    <Col xs={4}>
                        <div className="skeleton custom-card-img">
                            <Skeleton width={props.lineWidth} height={props.lineHeight}/>
                        </div>
                    </Col>
                    <Col xs={8}>
                        <div className="custom-card-body">
                            <Skeleton/>
                            <Skeleton count={props.totalLine}/>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="custom-card my-3">
                <Row row="2">
                    <Col xs={4}>
                        <div className="skeleton custom-card-img">
                            <Skeleton width={props.lineWidth} height={props.lineHeight}/>
                        </div>
                    </Col>
                    <Col xs={8}>
                        <div className="custom-card-body">
                            <Skeleton/>
                            <Skeleton count={props.totalLine}/>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="custom-card my-3">
                <Row row="3">
                    <Col xs={4}>
                        <div className="skeleton custom-card-img">
                            <Skeleton width={props.lineWidth} height={props.lineHeight}/>
                        </div>
                    </Col>
                    <Col xs={8}>
                        <div className="custom-card-body">
                            <Skeleton/>
                            <Skeleton count={props.totalLine}/>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="custom-card my-3">
                <Row row="4">
                    <Col xs={4}>
                        <div className="skeleton custom-card-img">
                            <Skeleton width={props.lineWidth} height={props.lineHeight}/>
                        </div>
                    </Col>
                    <Col xs={8}>
                        <div className="custom-card-body">
                            <Skeleton/>
                            <Skeleton count={props.totalLine}/>
                        </div>
                    </Col>
                </Row>
            </div>
        </>
    )
}

CustomCardSkeleton.defaultProps = {
    totalLine: 1,
    lineWidth: 100,
    lineHeight: 100,
}

CustomCardSkeleton.propTypes = {
    totalLine: PropTypes.number,
    lineWidth: PropTypes.number,
    lineHeight: PropTypes.number,
}

export default CustomCardSkeleton